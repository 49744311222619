import request from '@/utils/request'
const baseURL = '/baseapi'
const roleApi = {
  RoleList: '/admin/role/list',
  SetRoleStatus: '/admin/role/setRoleStatus',
  RoleInfo: '/admin/role/roleInfo',
  AddRole: '/admin/role/addRole',
  Condition: '/admin/role/condition',
  RoleUserInfo: '/admin/role/roleUserInfo',
  SetRolePower: '/admin/role/setRolePower'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function roleList (parameter) {
  return request({
    baseURL: baseURL,
    url: roleApi.RoleList,
    method: 'post',
    data: parameter
  })
}

export function setRoleStatus (parameter) {
  return request({
    baseURL: baseURL,
    url: roleApi.SetRoleStatus,
    method: 'post',
    data: parameter
  })
}

export function roleInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: roleApi.RoleInfo,
    method: 'post',
    data: parameter
  })
}

export function roleUserInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: roleApi.RoleUserInfo,
    method: 'post',
    data: parameter
  })
}

export function addRole (parameter) {
  return request({
    baseURL: baseURL,
    url: roleApi.AddRole,
    method: 'post',
    data: parameter
  })
}

export function roleCondition (parameter) {
  return request({
    baseURL: baseURL,
    url: roleApi.Condition,
    method: 'post',
    data: parameter
  })
}

export function setRolePower (parameter) {
  return request({
    baseURL: baseURL,
    url: roleApi.SetRolePower,
    method: 'post',
    data: parameter
  })
}
